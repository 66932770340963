﻿.win-type-lead {
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4;
}

.win-type-jumbo {
    font-size: 74px;
    font-weight: 500;
    line-height: 1.4;
}

h1.win-type-jumbo,
h3.win-type-lead {
  margin-top: 0px;
  margin-bottom: 0px;
}